<template>
  <v-card
    elevation="0"
    rounded="xl"
    min-height="300"
    :style="`
      position: fixed;
      width: 22%;
    `"
  >
    <v-list-item>
      <v-list-item-avatar size="40">
        <v-img :src="getResourceUrl(anna.avatar)" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="anna.name" />
        <!-- <v-list-item-subtitle v-text="createdAt" /> -->

        <v-list-item-subtitle>
          Automate content creation
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon>
        <v-container class="py-3">
          <v-row align="center">
            <span class="live-indicator"></span>
          </v-row>
        </v-container>
      </v-list-item-icon>
    </v-list-item>

    <v-card-text>
      Supercharge your content creation with our AI-powered platform. Our cutting-edge technology helps you generate high-quality articles, social posts, and product descriptions in seconds. Save time and resources while maintaining your unique voice. Whether you're a solo creator or managing a team, our tool adapts to your needs, ensuring consistent output and freeing you to focus on strategy and creativity.
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getResourceUrl } from '@utils'

  export default {
    computed: {
      ...mapGetters({
        anna: 'anna',
      }),
    },

    methods: {
      getResourceUrl
    }
  }
</script>
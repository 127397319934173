import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    like(commentId, profileId) {
      return apiRequest.post(
        `comment/like/${userId}`,
        { commentId, profileId }
      )
    },
    unlike(commentId, profileId) {
      return apiRequest.post(
        `comment/unlike/${userId}`,
        { commentId, profileId }
      )
    },
    create(body) {
      return apiRequest.post(
        `comment/${userId}`,
        body
      )
    }
  }
}

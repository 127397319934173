<template>
  <div>
    <v-card class="px-2" outlined rounded="lg">
      <div
        :id="`editorjs_${editorId}`"
        class="hs-editorjs--post"
        spellcheck="false"
        style="z-index: 2;"
        ref="postEditor"
      ></div>
    </v-card>
    <v-btn
      small
      color="primary"
      class="mt-2 ml-auto"
      :loading="submitting"
      :disabled="submitting"
      @click="submit"
    >
      {{  $t('community.comment') }}
    </v-btn>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { v4 as uuidv4 } from 'uuid';
  import DefaultEditorJS from '@components/DefaultEditorJS'
  import CommentService from '@api/comment'

  export default {
    props: {
      post: Object,
      setCommentToUnshift: Function
    },

    data() {
      return {
        editorId: uuidv4(),
        editor: null,
        commentService: null,
        submitting: false
      }
    },

    created() {
      this.commentService = CommentService(this.user.id)
      this.setEditor()
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        profileName: 'profile/name',
        profileUsername: 'profile/username',
        profileAvatar: 'profile/avatar',
      }),
    },

    methods: {
      async submit() {
        const content = await this.editor.saver.save()
        if (content.blocks.length === 0) return
        this.submitting = true
        const comment = await this.commentService.create({
          post: this.post._id,
          content,
          owner: this.profileId
        })
        this.setCommentToUnshift({
          ...comment,
          owner: {
            _id: this.profileId,
            name: this.profileName,
            username: this.profileUsername,
            avatar: this.profileAvatar
          },
          createdAt: new Date()
        })
        this.editor.clear()
        this.submitting = false
      },

      pushNewComment(comment) {
        if (!this.post.comments) this.post.comments = []
        this.post.comments.push(comment)
      },

      async setEditor() {
        const editorPromise = DefaultEditorJS.newEditor(this.editorId)
        await editorPromise.isReady;
        this.editor = editorPromise;
      }
    }
  }
</script>
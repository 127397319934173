<template>
  <v-container>
    <v-row>
      <PostHeader :post="post" />
    </v-row>
    <v-row>
      <div
        class="px-3 py-1 mt-2 w-100"
        style="width: 100%;"
      >
        <EditorJSViewer :content="post.content"/>
      </div>
    </v-row>
    <v-divider/>
    <v-row class="d-flex mt-3" style="align-items: center; justify-content: space-between;">
      <div>
        <LikeButton
          v-if="isActive"
          :post="post"
        />
        <CommentButton />
      </div>
      <span class="caption grey--text bold mr-3 clickable">
        {{ $t('community.comments') }} {{ commentsCount }}
      </span>
    </v-row>
    <v-row
      v-if="showComments"
      class="mt-3 mx-2"
      :style="`display: block;`"
    >
      <CommentTextarea
        :post="post"
        :setCommentToUnshift="setCommentToUnshift"
      />
    </v-row>
    <v-row 
      v-if="showComments"
      style="display: block;"
    >
      <CommentList
        :postId="post._id"
        :commentToUnshift="commentToUnshift"
      />
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProfileAvatar from '@components/Hall/ProfileAvatar'
  import PostHeader from '@components/Community/Post/PostHeader'
  import PostService from '@api/post'
  import LikeButton from '@components/Community/Post/LikeButton'
  import CommentButton from '@components/Community/Post/CommentButton'
  import CommentTextarea from '@components/Community/Post/CommentTextarea'
  import CommentList from '@components/Community/Post/CommentList'
  import EditorJSViewer from '@components/EditorJSViewer'

  export default {
    components: {
      ProfileAvatar,
      PostHeader,
      LikeButton,
      CommentButton,
      CommentTextarea,
      CommentList,
      EditorJSViewer
    },

    props: {
      post: Object,
      showComments: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        postService: null,
        commentOn: false,
        commentToUnshift: null,
        commentsCount: 0
      }
    },

    async created() {
      this.postService = PostService(this.user.id)
      this.loadCommentsCount()
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        membership: 'community/membership',
        isActive: 'community/isActive'
      })
    },

    methods: {
      setCommentToUnshift(comment) {
        this.commentToUnshift = comment
        this.commentsCount = this.commentsCount + 1
      },
      async loadCommentsCount() {
        this.commentsCount = await this.postService.getCommentsCount(this.post._id)
      }
    }
  }
</script>
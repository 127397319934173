var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollPagination',{attrs:{"requestData":{
    'url': ("comments/" + (this.user.id)),
    'params': {
      post: this.postId
    }
  },"countData":{
    'url': ("comments/" + (this.user.id) + "/count"),
    'params': {
      post: this.postId
    }
  },"rootHeight":'auto',"itemToUnshift":_vm.commentToUnshift},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('v-container',{staticClass:"mx-4"},[_c('v-row',[_c('PostHeader',{attrs:{"post":props.item}})],1),_c('v-row',[_c('div',{staticClass:"px-3 mt-2"},[_c('EditorJSViewer',{key:props.item._id,attrs:{"content":props.item.content}})],1)]),_c('v-divider',{staticClass:"mt-0"}),(_vm.isActive)?_c('v-row',{staticClass:"d-flex mt-2",staticStyle:{"align-items":"center"}},[_c('CommentLikeButton',{attrs:{"comment":props.item}})],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-btn
    text
    small
    class="ml-2 mr-2"
    :loading="submittingLike"
    :disabled="submittingLike"
    @click="onThumbClick"
    :style="liked ? `color: ${primaryColor}` : ''"
  >
    <v-icon :size="20" class="mr-1">
      {{ mdiThumbUp }}
    </v-icon>
    {{ $t(likeLabel) }}
  </v-btn>
</template>

<script>
  import { mdiThumbUp } from '@mdi/js'
  import PostService from '@api/post'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      post: Object
    },

    data() {
      return {
        mdiThumbUp,
        submittingLike: false,
        postService: null
      }
    },

    created() {
      this.postService = PostService(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
      }),

      liked() {
        return this.post.likes && this.post.likes.includes(this.profileId)
      },

      likeLabel() {
        return this.post.likes && this.post.likes.includes(this.profileId) ? 'community.liked' : 'community.like'
      },

      primaryColor() {
        const { isDark, themes: { light, dark } } = this.$vuetify.theme
        return isDark ? dark.primary : light.primary
      }
    },

    methods: {
      onThumbClick() {
        if (this.liked) return this.onDislike()
        return this.onLike()
      },

      async onLike() {
        this.submittingLike = true
        await this.postService.like(this.post._id, this.profileId)
        if (!this.post.likes) this.post.likes = []
        this.post.likes.push(this.profileId)
        this.submittingLike = false
      },

      async onDislike() {
        this.submittingLike = true
        await this.postService.unlike(this.post._id, this.profileId)
        this.post.likes = this.post.likes.filter(id => id !== this.profileId)
        this.submittingLike = false
      }
    }
  }
</script>
<template>
  <div class="d-flex">
    <div class="px-3">
      <ProfileAvatar
        :avatar="post.owner.avatar"
        :size="50"
      />
    </div>
    <div style="display: grid;">
      <div class="d-flex" style="align-items: center">
        <h3>{{ post.owner.name }}</h3>
        <span class="caption grey--text ml-3">
          {{ formatDate(post.createdAt) }}
        </span>
      </div>
      <div v-if="post.room">
        <span class="caption grey--text">
          {{ $t('community.postedIn') }} {{ post.room.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import ProfileAvatar from '@components/Hall/ProfileAvatar'
  import { format } from 'date-fns'

  export default {
    components: {
      ProfileAvatar
    },

    props: {
      post: Object
    },

    methods: {
      formatDate(date) {
        return format(new Date(date), 'dd/MM/yyyy HH:mm')
      },
    }
  }
</script>
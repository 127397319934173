<template>
  <div class="editor-viewer-container d-flex" style="justify-content: center; margin: auto; width: 100%;">
    <div
      :id="`editorjs_${editorId}`"
      class="hs-editorjs--post"
      spellcheck="false"
      style="z-index: 2; width: 100%;"
      :ref="ref"
    ></div>
  </div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import DefaultEditorJS from '@components/DefaultEditorJS'

  export default {
    props: {
      content: Object
    },

    watch: {
      content: {
        deep: true,
        handler() {
          this.setEditor()
        }
      }
    },

    created() {
      this.setEditor()
    },

    data() {
      return {
        editorId: uuidv4(),
        editor: null
      }
    },

    computed: {
      ref() {
        return `postEditor_${this.editorId}`
      }
    },

    methods: {
      async setEditor() {
        if (this.editor) this.editor.destroy()
        const editorPromise = DefaultEditorJS.newEditor(
          this.editorId,
          '',
          {
            readOnly: true,
            data: this.content
          }
        )
        await editorPromise.isReady;
        this.editor = editorPromise;
      }
    }
  }
</script>
<style scoped>
  .editor-viewer-container .codex-editor__redactor {
    margin: auto !important;
  }
  .editor-viewer-container .ce-block.ce-block--focused {
    max-width: 100% !important;
  }
</style>
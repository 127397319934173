<template>
  <ScrollPagination
    :requestData="{
      'url': `comments/${this.user.id}`,
      'params': {
        post: this.postId
      }
    }"
    :countData="{
      'url': `comments/${this.user.id}/count`,
      'params': {
        post: this.postId
      }
    }"
    :rootHeight="'auto'"
    :itemToUnshift="commentToUnshift"
  >
    <template v-slot:child="props">
      <v-container class="mx-4">
        <v-row>
          <PostHeader :post="props.item" />
        </v-row>
        <v-row>
          <div class="px-3 mt-2">
            <EditorJSViewer
              :content="props.item.content"
              :key="props.item._id"
            />
          </div>
        </v-row>
        <v-divider class="mt-0"/>
        <v-row
          v-if="isActive"
          class="d-flex mt-2"
          style="align-items: center;"
        >
          <CommentLikeButton :comment="props.item" />
        </v-row>
      </v-container>
    </template>
  </ScrollPagination>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PostHeader from '@components/Community/Post/PostHeader'
  import CommentLikeButton from '@components/Community/Post/CommentLikeButton'
  import CommentButton from '@components/Community/Post/CommentButton'
  import CommentTextarea from '@components/Community/Post/CommentTextarea'
  import ScrollPagination from '@components/App/ScrollPagination'
  import EditorJSViewer from '@components/EditorJSViewer'

  export default {
    components: {
      PostHeader,
      CommentLikeButton,
      CommentButton,
      CommentTextarea,
      ScrollPagination,
      EditorJSViewer
    },

    props: {
      postId: {
        type: String,
        required: true
      },
      commentToUnshift: {
        type: Object,
        default: null
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        isActive: 'community/isActive'
      })
    }
  }
</script>
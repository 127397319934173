import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    get (id) {
      return apiRequest.get(
        `post/${userId}`,
        { params: { id } }
      )
    },
    getByCommunity (communityId) {
      return apiRequest.get(
        `post/${userId}`,
        { params: { community: communityId } }
      )
    },
    getByCommunityRoom (roomId) {
      return apiRequest.get(
        `post/${userId}`,
        { params: { room: roomId } }
      )
    },
    like(postId, profileId) {
      return apiRequest.post(
        `post/like/${userId}`,
        { postId, profileId }
      )
    },
    unlike(postId, profileId) {
      return apiRequest.post(
        `post/unlike/${userId}`,
        { postId, profileId }
      )
    },
    getCommentsCount(postId) {
      return apiRequest.get(
        `comments/${userId}/count`,
        { params: { post: postId } }
      )
    }
  }
}

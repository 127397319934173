<template>
  <v-btn
    text
    small
    class="ml-2 mr-2"
    @click="onCommentClick"
  >
    <v-icon :size="20" class="mr-1">
      {{ mdiCommentText }}
    </v-icon>
    {{ $t('community.comment') }}
  </v-btn>
</template>

<script>
  import { mdiCommentText } from '@mdi/js'

  export default {
    props: {
      onCommentClick: Function
    },
    data() {
      return {
        mdiCommentText
      }
    }
  }
</script>